'use strict';

import slider from './modules/slider';
import accordeon from './modules/accordeon';
import menu from './modules/menu';
// import check from './modules/check';
import hide from './modules/hide';
import sendForm from './modules/sendForm';
import up from './modules/up';
import lang from './modules/lang';
import langChange from './modules/lang-change';

document.addEventListener('DOMContentLoaded', () => {
    slider({
        container: '.slider__main', 
        nextArrow: '.slider__next', 
        prevArrow: '.slider__prev', 
        slide: '.slider__item', 
        wrapper: '.slider__wrapper', 
        field: '.slider__inner' 
    });

    accordeon('.questions__top-section', '.questions__bottom-section', '.questions__open', 'questions__top-section_active');

    menu('.header__menu', '.header__menu-item', '.header__hamburger', 'header__menu_active', 'header__hamburger_active');

    hide('.vacancy__button', '.vacancy__field-form', '.vacancy__form', 'vacancy__field-form_active');

    sendForm('.vacancy__form', '.vacancy__example', '#answer', '.vacancy__submit', 'vacancy__loader', 'vacancy__loader_active', '.modal__close', '.overlay', '.modal__info', 'overlay_show');

    up('.pageup');

    lang('.header__item-link_drop', '.language', 'language_active', '.language__img');

    langChange('.language__img', '.video__src');
});